<script setup lang="ts">
  const config = useState<{title:string, icon:string, rntPage:string}>("pageconfig")
</script>
<template>
    <div>
        <Header></Header>
        <div class="h-max bg-slate-500 
        ">
            <!-- <PageHeader icon="folder" :title="config.title">
                <div class="flex ">                 

                </div>
            </PageHeader> -->


            <div class="     border border-slate-400">

               
                    
                    <slot ></slot>

               

            </div>




        </div>
        <Footer></Footer>
    </div>
</template>
<style lang="scss">
body {
    background-color: #cdcdcf;
}
</style>